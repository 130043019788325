import { defineStore } from 'pinia'
import api from '@/api'

let userInfo = localStorage.getItem('userInfo')
// console.log(userInfo, 'userInfo')
userInfo = userInfo && userInfo !== 'undefined' ? JSON.parse(userInfo) : { userId: '' }

const userStore = defineStore('user', {
  state: () => {
    return {
      env: process.env.NODE_ENV,
      isDev: process.env.NODE_ENV === 'development',
      isProd: process.env.NODE_ENV === 'production',
      appHomeName: process.env.VUE_APP_HOME_NAME,
      userInfo: userInfo,
      userShowPage: {
        page: 'orderList',
        data: '',
        selectType: '' // 选中页面
      }, //个人中心显示界面
      couponNum: 0, //劵数量
      favoritesNum: 0, // 我的喜欢
      cartCount: 0, // 用户购物车数量
      addressList: [] // 用户收货地址列表
    }
  },
  actions: {
    setUserInfo(val) {
      this.userInfo = { ...this.userInfo, ...val }
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
      console.log(this.userInfo, 'pinia更改userInfo')
    },
    // 退出登录
    setLoginOut(val) {
      // this.tokenExpire = val
      this.token = ''
      this.cartList = []
      this.userInfo = { userId: '' }
      // 退出时清除token
      localStorage.clear()
      localStorage.removeItem('userInfo')
      localStorage.removeItem(`${process.env.NODE_ENV}_token`)
    },
    // 更新token
    setToken(token) {
      // if (token) {
      //   this.token = token
      //   util.storage.set(config.tokenKey, token)
      //   // uni.setStorage({ key: config.tokenKey, data: token })
      // } else {
      //   this.token = ''
      //   // 退出时清除token
      //   util.storage.remove(config.tokenKey)
      //   // uni.removeStorage({ key: config.tokenKey })
      // }
    },
    setUserShowPage(val) {
      console.log(val, 'this.userShowPage')
      this.userShowPage = { ...this.userShowPage, ...val }
    },
    // 更新劵数量
    setCouponNum(val) {
      this.couponNum = val
    },
    // 更新收藏数量
    setCollectProduct(val) {
      this.favoritesNum = val
    },
    setAddressList(val) {
      this.addressList = val
      console.log(this.addressList, 'this.addressList')
    },
    getCartList() {
      if (!this.userInfo.userId) return
      api.product
        .getCartCount({
          'userId.equals': this.userInfo.userId
        })
        .then((res) => {
          this.cartCount = res
          console.log('查看购物车数量:', this.cartCount)
        })
    },
    updataCartList(val) {
      this.cartCount = val
    }
  },
  persist: {
    enabled: true,
    // 选择存储方式和内容
    strategies: [{ storage: localStorage, paths: ['addressList'] }]
  }
})

export default userStore
