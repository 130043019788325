// 登录相关
const loginRoutes = [
  {
    path: '/loginOther',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/register',
        name: 'REGISTER',
        component: () => import('@/views/customerService/register/index.vue'),
        meta: {
          cnName: '注册',
          enName: 'Register'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'LOGIN',
    component: () => import('@/views/login/index.vue'),
    meta: {
      cnName: '登录',
      enName: 'Login'
    }
  },
  {
    path: '/otherLogin',
    name: 'otherLogin',
    component: () => import('@/views/login/compontent/other-login.vue'),
    meta: {
      cnName: '其他登录',
      enName: 'Login'
    }
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/login/privacyPolicy/index.vue'),
    meta: {
      cnName: '隐私政策',
      enName: 'Privacy Policy'
    }
  }
  // 其他首页的路由配置
]

export default loginRoutes
