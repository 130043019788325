const blogRoutes = [
  {
    path: '/blog',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/blogs/:slug',
        name: 'blogDetail',
        component: () => import('@/views/blog/detail/index.vue'),
        meta: {
          cnName: '产品详情',
          enName: 'Blog Detail'
        }
      },
      {
        path: '/blogs',
        name: 'blogList',
        component: () => import('@/views/blog/index.vue'),
        meta: {
          cnName: '产品列表',
          enName: 'Blog List'
        }
      },
      {
        path: '/blogs/tags/:slug',
        name: 'BlogListByTag',
        component: () => import('@/views/blog/tags/index.vue'),
        meta: {
          cnName: '产品列表',
          enName: 'Blog List by Tag'
        }
      }
    ]
  }
]

export default blogRoutes
