// 活动路由
const activityRoutes = [
  {
    path: '/zindagi',
    name: 'zindagi',
    component: () => import('@/views/activity/zindagi/index.vue'),
    meta: {
      cnName: '活动页面',
      enName: 'Activity Page'
    }
  }
  // 其他活动相关的路由配置
]

export default activityRoutes
