<template>
  <div class="wrap">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
  export default {
    name: 'App',
    provide() {
      //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive: true //控制视图是否显示的变量
      }
    },
    computed: {},
    methods: {
      reload() {
        this.isRouterAlive = false //先关闭，
        this.$nextTick(function () {
          this.isRouterAlive = true //再打开
        })
      }
    }
  }
</script>
<style lang="less">
  :root,
  body {
    padding: 0;
    margin: 0;
    --el-menu-item-font-size: 14px;
    font-size: 12px;
    position: relative;
  }
  .wrap {
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
  }
  .el-select .el-input {
    height: 38px;
  }
  .el-input__wrapper {
    border-radius: 8px !important;
  }
</style>
