<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, watch, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  import { ElMessage } from 'element-plus'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['imgsCallback'])
  const props = defineProps({
    fileMaxSize: {
      // 默认最大体积M
      type: Number,
      required: false,
      default: 10
    },
    fileCount: {
      // 上传图片数量
      type: [Number, String],
      required: false,
      default: 1
    },
    fileType: {
      type: String,
      required: false,
      default: 'image/png, image/jpeg, image/jpg'
    },
    imgs: {
      // 传入图片列表
      type: Array
    },
    isDelete: {
      // 是否展示删除功能
      type: Boolean,
      default: true
    }
  })

  const inputRef = ref()
  const data = reactive({
    loading: false,
    imgList: [],
    previewDialog: false,
    previewUrl: '',
    selectedFiles: [] //已上传的files
  })

  watch(
    () => props.imgs,
    (newImgs, oldImgs) => {
      // console.log(newImgs, oldImgs, 'newImgs')
      if (!newImgs) return
      data.imgList = newImgs.filter((img) => img && img.trim() !== '')
      console.log(data.imgList, 'data.imgList')
    },
    { immediate: true }
  )

  const preSelect = () => {
    //点击上传图片之前
    inputRef.value.click()
  }

  const handleChange = (e) => {
    /* eslint-disable */
    //点击上传图片事件
    let BreakException = {}
    let file = e.target.files
    if (file.length + data.imgList.length > props.fileCount) {
      ElMessage.error(`Upload a maximum of ${props.fileCount} images`)
      return
    }
    try {
      for (let i = 0; i < file.length; i++) {
        if (!file[i].type || props.fileType.indexOf(file[i].type) === -1) {
          ElMessage.error(`JPG, JPEG and PNG format only`)
          throw BreakException
        } else if (file[i].size > props.fileMaxSize * 1024 * 1000) {
          ElMessage.error(`The picture is too large, please upload it again`)
          throw BreakException
        }
      }
    } catch (error) {
      // // console.log(error)
      if (e !== BreakException) throw e
    }

    const index = data.selectedFiles.findIndex((selectedFile) => {
      return selectedFile.name === file[0].name && selectedFile.size === file[0].size
    })
    if (index >= 0) return
    // 将不重复的文件添加到已选择的文件列表中
    data.selectedFiles.push(...file)

    let fileSucCount = 0
    for (let i = 0; i < file.length; i++) {
      let formData = new FormData()
      formData.set('file', file[i])
      // console.log(formData, file[i], 'formData')
      data.loading = true
      api.common
        .uploadsFile(formData)
        .then((res) => {
          // console.log(res, 'resresresres')
          data.imgList.push(res)
          $emit('imgsCallback', data.imgList)
          fileSucCount = fileSucCount + 1
          if (fileSucCount === file.length) {
            data.loading = false
          }
        })
        .catch((error) => {
          data.loading = false
        })
    }
  }

  const deleteImg = (ind) => {
    // 删除图片
    data.imgList.splice(ind, 1)
    $emit('imgsCallback', data.imgList)
  }

  // 预览图片
  const handlePictureCardPreview = (index) => {
    data.previewUrl = data.imgList[index]
    data.previewDialog = true
  }
</script>

<template>
  <div class="image-wrapper-box" v-loading="data.loading">
    <div class="img-wrapper rowSS">
      <div class="rowSC img-list-box">
        <template v-if="data.imgList.length">
          <div class="item" v-for="(item, index) in data.imgList" :key="index">
            <JLImg :src="item" class="dis-image" />
            <span class="operation-box">
              <span class="item-preview icon-item" @click="handlePictureCardPreview(index)">
                <i class="icon">
                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="currentColor"
                      d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z"
                    ></path>
                  </svg>
                </i>
              </span>
              <span v-if="isDelete" class="item-delete icon-item" @click="deleteImg(index)">
                <i class="icon">
                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="currentColor"
                      d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"
                    ></path>
                  </svg>
                </i>
              </span>
            </span>
          </div>
        </template>
        <div
          class="upload-icon"
          @click="preSelect"
          v-if="data.imgList.length < fileCount"
          :class="data.imgList.length > 0 && data.imgList.length < fileCount ? 'upload-extend' : ''"
        >
          <span class="heng"></span>
          <span class="su"></span>
        </div>
      </div>
    </div>
    <input type="file" class="inputer" ref="inputRef" @change="handleChange($event)" multiple="multiple" />
  </div>

  <el-dialog v-model="data.previewDialog">
    <JLImg :src="data.previewUrl" quality="95" class="wh100" alt="Preview Image" />
  </el-dialog>
</template>

<style lang="less" scoped>
  .image-wrapper-box {
    width: 100%;
    height: 100%;
    .img-list-box {
      flex-wrap: wrap;
    }
  }

  .inputer {
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 100px;
    display: none;
  }

  .img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    cursor: pointer;

    .upload-icon {
      position: relative;
      width: 100px;
      height: 100px;
      min-width: 70px;
      min-height: 70px;
      text-align: center;
      border: 1px solid #dcdfe6;
      border-radius: 6px;
      max-width: 100px;
      max-height: 100px;

      .heng {
        position: absolute;
        display: inline-block;
        width: 30px;
        height: 3px;
        background: #dbdbdb;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .su {
        position: absolute;
        display: inline-block;
        width: 3px;
        height: 30px;
        background: #dbdbdb;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background: rgba(224, 228, 229, 0.34);
      }
    }

    .upload-extend {
      margin: 0 8px 8px 0;
    }
  }

  .img-wrapper .item {
    position: relative;
    width: 100px;
    height: 100px;
    min-width: 70px;
    min-height: 70px;
    text-align: center;
    margin: 0 8px 8px 0;
    border: 1px solid #dcdfe6;
    border-radius: 6px;
    .dis-image {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }

    .operation-box {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: var(--el-overlay-color-lighter);
      transition: opacity var(--el-transition-duration);
      border-radius: 6px;
      .icon-item {
        margin-left: 20px;
        .icon {
          height: 1em;
          width: 1em;
          color: #fff;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      .operation-box {
        opacity: 1;
      }
    }
  }
</style>
