import api from '@/api/request'
import { flux } from '@/util/func'
import useStore from '@/store/pinia'

// 个人中心-用户汇总
export function getPersonalCenters(userId) {
  return api.get(`/services/common/api/personal-centers/${userId}`)
  // 红点数据：redDotHintMap
  // 订单物流信息：orderLogisticList
  // 最快过期的一张优惠券：couponRecord
  // 可用优惠券Count数：couponCount
  // 被回复数:repliedCount
  // 文章收藏数:articleCollectCount
  // 商品收藏数:productCollectCount
  // 购物车规格Count数:cartSpecCount
  // 钱包余额:balance
  // 推荐商品:productList
}

// 个人中心-返回主订单每种订单状态的订单数量
export function getPurchaseOrdersStatus(params) {
  return api.get(`/services/order/api/main/order/count`, params)
}

export function updateUser(params) {
  return api.patch('/services/user/api/user-profiles', params)
}

// 获取用户信息
export function getUserInfo(data) {
  return api
    .get('/services/user/api/user-profiles/userId/' + data)
    .then((res) => {
      if (res) {
        const { userStore } = useStore()
        userStore.setUserInfo(res)
      }
      return flux.resolve(res)
    })
    .catch((err) => {
      return flux.reject(err)
    })
}

// 更新用户密码
export function updatePasswordReq(data) {
  return api.post('/services/uaa/api/account/change-password', data)
}

// 获取验证码
export function getCode(mobile) {
  // return api.post('services/user/api/sms', data)
  return api.post(`services/user/api/sms/${mobile}`)
}

// code登录
export function login(data) {
  // 清除token
  localStorage.removeItem(`${process.env.NODE_ENV}_token`)
  return api
    .post_form('services/user/api/sms/login', data)
    .then((res) => {
      if (res && res.token && res.userDTO) {
        localStorage.setItem(`${process.env.NODE_ENV}_token`, res.token.access_token)
      }
      return flux.resolve(res)
    })
    .catch((err) => {
      return flux.reject(err)
    })
}

// 密码登录|密码注册
export function passwordLogin(data) {
  // 清除token
  localStorage.removeItem(`${process.env.NODE_ENV}_token`)
  return api
    .post_form('services/user/api/sms/pw/login', data)
    .then((res) => {
      if (res && res.token && res.userDTO) {
        localStorage.setItem(`${process.env.NODE_ENV}_token`, res.token.access_token)
      }
      return flux.resolve(res)
    })
    .catch((err) => {
      return flux.reject(err)
    })
}

// 修改密码
export function modifyPassword(data) {
  return api.put(`services/user/api/user/modify/password`, data)
}

// 设置密码
export function setPassword(data) {
  return api.put(`services/user/api/user/set/password`, data)
}

// 是否设置过密码
export function isPassWordChanged(data) {
  return api.get(`services/uaa/api/find/changed/complete`, data)
}
