"use strict"

import axios from "axios"

const Plugin = {}

Plugin.install = function (Vue) {
    Vue.axios = axios
    window.axios = axios
    Object.defineProperties(Vue.config.globalProperties, {
        axios: {
            get() {
                return axios
            }
        },
        $axios: {
            get() {
                return axios
            }
        },
    })
}

export default Plugin
