import api from '@/api/request'

// 优惠卷系列接口

// 查询用户已领取优惠券
export function getCouponByUseridReq(userId) {
  const date = new Date() // Replace this with your date object
  const nowTime = date.toISOString()
  const data = {
    'executed.equals': true,
    'used.equals': false,
    'effectEndAt.greaterThanOrEqual': `${nowTime}`,
    'userId.equals': `${userId}`,
    sort: 'id,desc',
    size: 1000,
    page: 0,
    'codeType.equals': true,
    'isuse.equals': 0
  }
  return api.get(`services/coupon/api/coupon-records`, data)
}

// 查询用户可领取优惠券
export function getCouponUsed(userId, params) {
  const data = {
    'userId.equals': `${userId}`,
    sort: 'id,desc',
    size: 1000,
    page: 0
  }
  const mergedObj = { ...data, ...params }
  // console.log(mergedObj, 'mergedObj')
  return api.get(`services/coupon/api/coupon-records`, mergedObj)
}

// Coupons Center 查询卷中心卷列表
export function singleclick(userId, data) {
  // 登录|未登录
  const url = userId ? `singleclick/${userId}` : `notLogin/singleclick`
  return api.get(`/services/coupon/api/haier/coupons/${url}`, data)
}

// 领取优惠卷
export function recieveCoupon(id, userId) {
  return api.post(`/services/coupon/api/issue/${id}/${userId}/by-userid`)
}

// 查询券数量
export function queryCouponNum(data) {
  return api.get(`services/coupon/api/coupon-records/count`, data)
}

// 优惠卷兑换码兑换
export function getCouponCode(code, userId) {
  return api.post(`services/coupon/api/issue/${code}/${userId}/code`)
}

// 优惠卷兑换码分享
export function getCouponShare(couponId) {
  return api.post(`services/coupon/api/coupon-records/${couponId}/share`)
}
