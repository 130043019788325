export default {
  install(app) {
    app.directive('push', {
      mounted(el, binding) {
        el.style.cursor = 'pointer'
        el.addEventListener('click', () => {
          app.config.globalProperties.$router.push({ path: binding.value })
        })
      }
    })
    // 全局挂载
  }
}
