<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const props = defineProps({
    text: {
      type: String,
      default: ''
    },
    isTips: {
      // 是否暂时tips提示
      type: Boolean,
      default: true
    },
    line: {
      // 最多展示几行
      type: Number,
      default: 3
    }
  })
</script>

<template>
  <div>
    <el-tooltip v-if="props.isTips && text.length > 100" class="box-item" effect="dark" :content="props.text" placement="top-start" popper-class="tooltop-box">
      <div class="hypertext text_hide3" :style="{ '-webkit-line-clamp': line ? line : 'unset' }">
        {{ props.text }}
      </div>
    </el-tooltip>
    <div v-else class="hypertext text_hide3" :style="{ '-webkit-line-clamp': line ? line : 'unset' }">
      {{ props.text }}
    </div>
  </div>
</template>

<style>
  .tooltop-box {
    max-width: 400px;
    word-break: break-all;
  }
</style>
<style lang="less" scoped>
  .hypertext {
    word-break: break-all;
  }
</style>
