import api from '@/api/request'
import useStore from '@/store/pinia'

// 订单系列接口

// 创建订单
export function createOrder(params, couponRecordId) {
  const { userStore } = useStore()
  // couponRecordId 优惠卷id 没有传0
  couponRecordId = couponRecordId || 0
  console.log(couponRecordId, 'couponRecordId')
  if (userStore.appHomeName === 'PRICOO') {
    return api.post(`/services/order/api/purchase-orders-coupon/multi/${couponRecordId}`, params)
  }
  return api.post(`/services/order/api/purchase-orders-coupon/batch/${couponRecordId}`, params)
}

// 查询订单[弃用]
export function orderDetail(ids) {
  // return api.get(`/services/order/api/purchase-orders/with-expand/id=${ids}`)
  return api.get(`/services/order/api/purchase-orders/with-expand?id.in=${ids}`)
}

// 查询订单列表[20230920更新]
export function orderList(params) {
  return api.get(`/services/order/api/purchase-orders/list`, params)
}

// 查询订单详情[20230921更新]
export function ordersDetail(params) {
  return api.get(`/services/order/api/purchase-orders/detail`, params)
}

// 查询订单追踪|包含订单详情[20240808更新]
export function ordersTrackInfo(mainOrderNo) {
  return api.get(`/services/order/api/track/info/${mainOrderNo}`)
}

// 根据订单ID确认收货
export function comfirmReceipt(mainOrderNo) {
  return api.post('/services/order/api/purchase-orders/receipt/' + mainOrderNo)
}

// 根据订单ID关闭订单
export function closeOrderNo(mainorderNo) {
  return api.post('/services/order/api/main/orders/close/' + mainorderNo)
}

// 根据订单ID取消订单
export function cancelOrderNo(mainorderNo) {
  return api.put(`services/order/api/cancel/order/${mainorderNo}`)
}

// 查询历史订单
export function purchaseOrders(data) {
  return api.get('services/order/api/purchase-orders', data)
}

// 查询历史订单
export function bankTransfer(data, type) {
  type = type || 'get'
  switch (type) {
    case 'post': // 上传银行转账凭据
      return api.post('services/order/api/bank/transfer', data)
    case 'put': // 修改银行转账凭据
      return api.put('services/order/api/bank/transfer', data)
    case 'delete': // 撤回银行凭据
      return api.del('services/order/api/bank/transfer', data)
    default: // 查询订单的银行凭据
      return api.get('services/order/api/bank/transfer', data)
  }
}

// 获取订单状态
export function getOrderStatus(params) {
  return api.get(`/services/order/api/main/order/status`, params)
}

/**
 * 新增商品|子订单评价
 * @param data
 */
export function addProductEvaluate(data) {
  return api.post('/services/product/api/comment-records', data)
}
