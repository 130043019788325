<template lang="html">
  <div v-show="isShowToast" class="toast-container" @touchmove.prevent @click="closeToast">
    <!-- 这里content为双花括号 -->
    <div class="toast-msg">
      <div style="margin-top: 5px">
        <img v-if="type === 'succeed'" class="img" src="@/assets/imgs/common/succeed.png" />
        <img v-else-if="type === 'error'" class="img" src="@/assets/imgs/common/error.png" />
        <img v-else class="img" src="@/assets/imgs/common/warning.png" />
      </div>
      <div style="margin-top: 10px">{{ message }}</div>
    </div>
  </div>
</template>
<script>
  import { getCurrentInstance, reactive, toRefs } from 'vue'
  export default {
    name: 'Message',
    props: {
      type: {
        type: String,
        default: 'warning'
      },
      message: {
        type: String,
        default: 'HELLO'
      },
      isShowToast: {
        type: Boolean,
        default: true
      },
      closeTime: {
        type: Number,
        default: 3000
      }
    },

    setup(props) {
      const { proxy } = getCurrentInstance()
      const data = reactive({
        toastFlag: null
      })

      const { close } = proxy.$attrs
      setTimeout(() => {
        close()
      }, 3000)
      const closeToast = () => {
        // // console.log("123231")
        close()
      }
      return {
        ...toRefs(data),
        closeToast
      }
    }
  }
</script>

<style lang="less" scoped>
  .toast-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2002;
  }
  .toast-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 360px;
    max-width: 900px;
    min-height: 120px;
    padding: 20px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 26px;
    color: #666;
    background: #fff;
    text-align: center;
    color: var(--btnBgColor);
    .img {
      width: 40px;
      height: 40px;
    }
  }
</style>
