import { createApp } from 'vue'
import Toast from './Toast.vue'

function createMount(message, opts) {
  const mountNode = document.createElement('div')
  document.body.appendChild(mountNode)
  function close() {
    if (!mountNode || !mountNode.__vue_app__) {
      return false
    }
    app.unmount(mountNode)
    document.body.removeChild(mountNode)
  }
  const app = createApp(Toast, {
    message,
    ...opts,
    isOpen: true,
    close
  })

  return app.mount(mountNode)
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$toast = createMount
  }
}
