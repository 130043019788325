import { createApp } from 'vue'
import Message from "./Message.vue"

const Plugin = {}
let $node

const defaultsOptions = {
    isOpen:true,
    title: "提示",
    msg: "内容"
}


// 创建挂载实例
function createMount(options) {
    //创建虚拟结点Node
    const mountNode = document.createElement('div')
    //将挂载的Node添加到body中
    document.body.appendChild(mountNode)
    //关闭（移除）组件方法
    const remove = () => {
        app.unmount(mountNode)
        document.body.removeChild(mountNode)
        $node = null
    }
    //绑定实例化组件
    const app = createApp(Message,{...options,isOpen: true,remove})
    $node = app.mount(mountNode)
}


Plugin.install = function(app) {
    const Message = {
        confirm(options) {
            if(!$node) {
                createMount(options)
            }
            let close = $node.close
            $node.close = () => {
                close()
                options && options.onCancel && options.onCancel()
            }
            let ok = $node.ok
            $node.ok = () => {
                ok()
                options && options.onOk && options.onOk()
            }
            return $node
        },
        info(options) {

        },
        success(options) {

        },
        error(options) {

        },
        warning(options) {

        }
    }
    app.config.globalProperties.$Message = Message
}
export default Plugin


