<template>
  <Teleport to="body">
    <div class="model" v-show="data.visible">
      <div class="component-box">
        <div class="model-box" :style="{ width: width }">
          <div class="component-header">
            <div class="title">{{ title }}</div>
          </div>
          <div class="component-type" v-if="type">
            <div class="icon" v-if="type === 'succeed'">
              <img class="img" src="@/assets/imgs/common/succeed.png" />
            </div>
            <div class="icon" v-else-if="type === 'error'">
              <img class="img" src="@/assets/imgs/common/error.png" />
            </div>
            <div class="icon" v-else>
              <img class="img" src="@/assets/imgs/common/warning.png" />
            </div>
          </div>
          <div class="component-body">
            <slot name="center"></slot>
          </div>
          <div class="component-footer">
            <button class="btn" v-if="isOk" @click="okClick">{{ $t(okText) }}</button>
            <button class="btn" v-if="isCancel" @click="cancelClick">{{ $t('Cancel') }}</button>
          </div>
          <div @click="cancelClick" class="close">×</div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
  import { reactive } from 'vue'

  export default {
    name: 'Modal',
    props: {
      width: {
        type: String,
        default: '540px'
      },
      title: {
        type: String,
        default: ''
      },
      visible: {
        type: Boolean,
        default: false
      },
      okText: {
        type: String,
        default: 'Ok'
      },
      type: {
        type: String,
        default: ''
      },
      isOk: {
        type: Boolean,
        default: false
      },
      isCancel: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {
      const data = reactive({
        modelFlag: null,
        visible: props.visible
      })
      const okClick = () => {
        data.visible = false
        context.emit('ok')
      }
      const cancelClick = () => {
        data.visible = false
        context.emit('cancel')
      }

      return {
        data,
        okClick,
        cancelClick
      }
    }
  }
</script>

<style scoped>
  .model {
    position: fixed;
    z-index: 900;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    .model-box {
      position: relative;
      width: 540px;
      height: 100%;
      padding: 10px;
    }
  }

  .component-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 15px #aaa;
    background: #fff;
  }

  .component-header {
    width: 100%;
    padding: 0 20px;
    min-height: 20px;

    .title {
      font-weight: bold;
      text-align: center;
      font-size: 20px;
    }
  }

  .component-type {
    margin: 0 auto;
    .icon {
      text-align: center;
      .img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .component-body {
    width: 100%;
    padding: 0 20px 10px;
    font-size: 14px;
    overflow-y: auto;
  }

  .component-footer {
    text-align: center;
    width: 100%;

    .btn {
      line-height: 24px;
      color: #fff;
      margin: 0 10px;
      padding: 0 12px;
      background: var(--btnBgColor);
      border-radius: 45px;
      width: 80px;
      height: 30px;
      font-weight: 500;
      font-size: 14px;

      &:last-child {
        background: #ffffff;
        border: 3px solid #222222;
        border-radius: 56px;
        color: #222222;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  .close {
    position: absolute;
    top: 0px;
    right: 8px;
    font-size: 24px;
    cursor: pointer;
  }
</style>
