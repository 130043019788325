import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import { setupPinia } from '@/store/pinia.js'
import i18n from './i18n'
import axios from './plugins/axios'
import apis from './api'
import directives from './directives'
import JLTools from '@/util/JLTools'
import mitt from "mitt"
import Modal from "@/components/Modal/modal"
import Toast from '@/components/Toast/toast'
import message from '@/components/Message'
import JLImg from "@/components/JLImg"
import imgUpload from '@/components/uploadImg'
import hypertext from '@/components/hypertext'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "@/assets/fonts/font.css"
import "@/assets/icon/iconfont.css"
// 导入样式文件
import '@/assets/css/index.less'


/*if (process.env.NODE_ENV !== 'production') {
    // console.log(process.env)
}*/

//进入新页面回到浏览器顶部
router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

const app = createApp(App)
    .use(i18n)
    .use(axios)
    .use(apis)
    .use(directives)
    .use(router)
    .use(JLTools)
    .use(Toast)
    .use(message)
    .use(Modal)
    .use(ElementPlus)

app.use(setupPinia(app))

app.component('JLImg', JLImg)
app.component('img-upload', imgUpload)
app.component('hypertext', hypertext)

app.config.globalProperties.$mybus = new mitt()


app.mount('#app')


export default app
