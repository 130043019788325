import app from '../main'
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

// 注册路由
function autoloadModuleRoutes() {
  const routes = []
  const moduleContext = require.context('./modules', false, /\.js$/)
  register(routes, moduleContext)
  return routes
}

// 绑定路由
function register(routes, moduleContext) {
  moduleContext.keys().forEach((key) => {
    const module = moduleContext(key)
    routes.push(module.default || module) // 添加了 || module
  })
}

// 获取所有模块路由
function getAllRoutes() {
  const allRoutes = autoloadModuleRoutes()
  // console.log('allRoutes', allRoutes)
  const mergedRoutes = []
  Object.values(allRoutes).forEach((router) => {
    mergedRoutes.push(...router)
  })
  return mergedRoutes
}

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes: getAllRoutes(),
  scrollBehavior(to, from, savedPosition) {
    //页面刷新回到浏览器顶部
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  if (app && app.$t) {
    const title = to.name || ''
    document.title = app.$t(title)
  }
  next()
})

export default router
