import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import user from './module/user.js'
import Hsystem from './module/Hsystem'

const pinia = createPinia()
pinia.use(piniaPersist)

export function setupPinia(app) {
  pinia.use(({ store }) => {
    // ... 这里安装插件
  })

  app.use(pinia)
  return app
}

// 统一导出 useStore 方法
export default function useStore() {
  return {
    userStore: user(),
    HsystemStore: Hsystem()
  }
}
