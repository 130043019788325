import api from '@/api/request'

// 查询地址
export function handleAddress(data, type) {
  type = type || 'get'
  switch (type) {
    case 'post': // 新增地址
      return api.post('/services/user/api/delivery-addresses', data)
    case 'put': // 修改地址
      return api.put('/services/user/api/delivery-addresses', data)
    case 'delete': // 删除地址
      return api.del(`/services/user/api/delivery-addresses/${data}`)
    default: // 查询地址
      return api.get('/services/user/api/delivery-addresses', data)
  }
}

// 查询城市信息
export function getAreaTree (data) {
  //收货地址 查询，创建，修改
  return api.post('/services/common/api/area-tree/' + data)
}